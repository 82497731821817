import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import Select from "@mui/material/Select";
import { Typography } from "@mui/material";

const CustomSelect = ({
  value,
  label,
  handleChange,
  handleBlur,
  items,
  labelStyle,
  sx,
  placeholder,
  placeholderStyle = {
    color: "#898989",
    fontSize: "12px",
    fontWeight: "400",
    borderRadius: "7.5px",
  },
  name,
  ...props
}) => {
  return (
    <Box sx={{ minWidth: 100, sx }}>
      <Typography
        style={{
          color: "#000000",
          fontWeight: "600",
          fontSize: "15px",
          ...labelStyle,
        }}
      >
        {label}
      </Typography>
      <FormControl fullWidth {...props}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          sx={{
            backgroundColor: "#FDFDF9",
            borderRadius: "10px",
            border: "1px solid #DCDCDC",
            p: "2px 12px",
          }}
          input={
            <InputBase
              sx={{
                ...sx,
                fontSize: "12px",
                fontWeight: "400",
                color: "#898989",
                ...placeholderStyle,
              }}
              placeholder={placeholder}
            />
          }
        >
          {items.map((item) => (
            <MenuItem value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CustomSelect;
