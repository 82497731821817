import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";

export const attendaceApi = createApi({
  reducerPath: "attendace",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Attendance"],
  endpoints: (builder) => ({
    getAttendaceData: builder.query({
      query: ({
        page = 1,
        search,
        startDate = "",
        endDate = "",
        issue = false,
        userId = "",
      }) => ({
        url: Apis.getAttendance,
        params: {
          page,
          search,
          startDate,
          endDate,
          ...(issue && { issue }),
          ...(userId && { userId }),
        },
      }),
      providesTags: ["Attendance"],
      transformResponse: (res) => res,
      keepUnusedDataFor: 180,
    }),
  }),
});

export const { useGetAttendaceDataQuery } = attendaceApi;
