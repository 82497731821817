import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";
import { API_AXIOS } from "../../http/interceptor";

const getCommission = async () => {
  try {
    const { data } = await API_AXIOS.get(Apis.getCommissionData);
    return { data };
  } catch (error) {
    return {
      error: error.response ? error.response.data : error.message,
    };
  }
};

export const commissionDataApi = createApi({
  reducerPath: "commission",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Commission"],
  endpoints: (builder) => ({
    getCommissionData: builder.query({
      queryFn: getCommission,
      providesTags: ["Commission"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetCommissionDataQuery } = commissionDataApi;
