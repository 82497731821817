import { lazy } from "react";
import {
  RouterProvider,
  createBrowserRouter,
  useRouteError,
} from "react-router-dom";
import Loadable from "./components/Loadable";
// import Login from "./pages/Login";
import MainLayout from "./layouts/MainLayout";
// import UserList from "./pages/Users/UserList";
import "./App.css";
import { Box } from "@mui/material";
import Login from "./pages/Login";
/*HR Management*/
import HrmsMainLayout from "./layouts/HrmsMainLayout";
//import { HrDashboard } from "./pages/HRMS/Dashboard/Dashboard";
//import { HrAddUser } from "./pages/HRMS/Users/AddUser";
//import { Attendence } from "./pages/HRMS/Attendence/Attendence";
//import { User } from "./pages/HRMS/Users/User";
//import { UserDetails } from "./pages/HRMS/Users/UserDetails";
//import { UserUpdate } from "./pages/HRMS/Users/UserUpdate";
//import { AttendenceDetails } from "./pages/HRMS/Attendence/AttendenceDetails";
//import { AllLeaveList } from "./pages/HRMS/Leaves/AllLeaveList";
//import { AddLeave } from "./pages/HRMS/Leaves/AddLeave";
//import { LeaveDetails } from "./pages/HRMS/Leaves/LeaveDetails";
import { UserApplication } from "./pages/HRMS/Users/UserApplication";
import Protected from "./components/Protected";

const HrDashboard = Loadable(
  lazy(() => import("./pages/HRMS/Dashboard/Dashboard"))
);
const HrAddUser = Loadable(lazy(() => import("./pages/HRMS/Users/AddUser")));
const Attendance = Loadable(
  lazy(() => import("./pages/HRMS/Attendence/Attendance"))
);
const AgentAttendence = Loadable(
  lazy(() => import("./pages/HRMS/Attendence/AgentAttendence"))
);
const User = Loadable(lazy(() => import("./pages/HRMS/Users/User")));
const UserDetails = Loadable(
  lazy(() => import("./pages/HRMS/Users/UserDetails"))
);
const UserUpdate = Loadable(
  lazy(() => import("./pages/HRMS/Users/UserUpdate"))
);
const AttendenceDetails = Loadable(
  lazy(() => import("./pages/HRMS/Attendence/AttendenceDetails"))
);
const AllLeaveList = Loadable(
  lazy(() => import("./pages/HRMS/Leaves/AllLeaveList"))
);
const AddLeave = Loadable(lazy(() => import("./pages/HRMS/Leaves/AddLeave")));
const LeaveDetails = Loadable(
  lazy(() => import("./pages/HRMS/Leaves/LeaveDetails"))
);
const Projects = Loadable(lazy(() => import("./pages/Projects/ProjectList")));
const AddProject = Loadable(
  lazy(() => import("./pages/Projects/CreateProject"))
);
const ProjectDetails = Loadable(
  lazy(() => import("./pages/Projects/PaymentDetails"))
);

const Payments = Loadable(lazy(() => import("./pages/Payments/PaymentList")));
const PaymentDetails = Loadable(
  lazy(() => import("./pages/Payments/PaymentDetails"))
);

const About = Loadable(lazy(() => import("./pages/About/index")));

const Forget = Loadable(lazy(() => import("./pages/Forget/ForgetPassword")));
const Dashboard = Loadable(lazy(() => import("./pages/Dashboard")));
const UserList = Loadable(lazy(() => import("./pages/Users/UserList")));
const AddUser = Loadable(lazy(() => import("./pages/Users/AddUser")));
const AddTeam = Loadable(lazy(() => import("./pages/Teams/AddTeam")));
const TeamList = Loadable(lazy(() => import("./pages/Teams/TeamList")));
const LeadList = Loadable(lazy(() => import("./pages/Leads/LeadList")));
const LeadPool = Loadable(lazy(() => import("./pages/Leads/LeadPool")));
const AddLead = Loadable(lazy(() => import("./pages/Leads/AddLead")));
const LeadDetails = Loadable(lazy(() => import("./pages/Leads/LeadDetails")));
const MeetingList = Loadable(
  lazy(() => import("./pages/Meetings/MeetingList"))
);
const AddMeeting = Loadable(lazy(() => import("./pages/Meetings/AddMeeting")));
const MeetingDetails = Loadable(
  lazy(() => import("./pages/Meetings/MeetingDetails"))
);
const AddBooking = Loadable(lazy(() => import("./pages/Bookings/AddBooking")));
const BookingList = Loadable(
  lazy(() => import("./pages/Bookings/BookingList"))
);
const BookingDetails = Loadable(
  lazy(() => import("./pages/Bookings/BookingDetails"))
);
const DeveloperList = Loadable(
  lazy(() => import("./pages/Developers/DeveloperList"))
);

function App() {
  const router = createBrowserRouter([
    {
      path: "/auth/login",
      element: <Login />,
    },
    {
      path: "/auth/forget",
      element: <Forget />,
    },
    {
      path: "about",
      element: <About />,
    },
    {
      path: "userApplication",
      element: <Protected />,
      children: [
        {
          path: "",
          element: <UserApplication />,
        },
      ],
    },

    {
      path: "/HrManagement",
      element: <Protected />,
      children: [
        {
          path: "",
          element: <HrmsMainLayout />,
          children: [
            {
              path: "dashboard",
              element: <HrDashboard />,
            },
            {
              path: "users",
              element: <User />,
            },
            {
              path: "addUser",
              element: <HrAddUser />,
            },
            {
              path: "userDetails/:id",
              element: <UserDetails />,
            },
            {
              path: "userUpdate",
              element: <UserUpdate />,
            },
            {
              path: "attendance",
              element: <Attendance />,
            },
            {
              path: "individualAtt/:id",
              element: <AgentAttendence />,
            },
            {
              path: "attendenceDetails/:id",
              element: <AttendenceDetails />,
            },
            {
              path: "leaves",
              element: <AllLeaveList />,
            },
            {
              path: "addLeave",
              element: <AddLeave />,
            },
            {
              path: "leaveDetails/:id",
              element: <LeaveDetails />,
            },
          ],
        },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "project",
          element: <Projects />,
        },
        {
          path: "project/create-project",
          element: <AddProject />,
        },
        {
          path: "payment",
          element: <Payments />,
        },
        {
          path: "payment/payment-details",
          element: <PaymentDetails />,
        },
        {
          path: "project/:id",
          element: <ProjectDetails />,
        },
        {
          path: "users",
          element: <UserList />,
        },
        {
          path: "users/addUser",
          element: <AddUser />,
        },
        {
          path: "users/addTeam",
          element: <AddTeam />,
        },
        {
          path: "users/teams",
          element: <TeamList />,
        },
        {
          path: "leads",
          element: <LeadList />,
        },
        {
          path: "leads/leadpool",
          element: <LeadPool />,
        },
        {
          path: "leads/:id",
          element: <LeadDetails />,
        },
        {
          path: "leads/addLead",
          element: <AddLead />,
        },
        {
          path: "meetings",
          element: <MeetingList />,
        },
        {
          path: "meetings/addMeeting",
          element: <AddMeeting />,
        },
        {
          path: "meetings/:id",
          element: <MeetingDetails />,
        },
        {
          path: "bookings",
          element: <BookingList />,
        },
        {
          path: "bookings/addBooking",
          element: <AddBooking />,
        },
        {
          path: "bookings/:id",
          element: <BookingDetails />,
        },
        {
          path: "developers",
          element: <DeveloperList />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
        backgroundColor: "#f8f8f8",
      }}
    >
      <h1>An error occurred</h1>
      <p>Sorry, something went wrong.</p>
    </Box>
  );
}
export default App;
