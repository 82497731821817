import PersonIcon from "@mui/icons-material/Person";
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PeopleIcon from '@mui/icons-material/People';



export const HrmsSideBarList= [
    {
        text: "Dashboard",
        icons: DashboardCustomizeIcon,
        link: "/HrManagement/dashboard",
        
    },
    {
        text: "Users",
        icons: PeopleIcon,
        link: "/HrManagement/users",
        
    },
    {
        text: "Attendance",
        icons: TaskAltIcon,
        link: "/HrManagement/attendance",
        customLink: '/HrManagement/individualAtt'
    },
    {
        text: "Leaves",
        icons: PersonIcon,
        link: "/HrManagement/leaves",
        
    }
]