import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Backdrop from "@mui/material/Backdrop";

import Logo from "../../assets/AuthLogo.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

// HrManagement
import { Header } from "./Header";
import { HrmsSideBarList } from "../../utils/HrmsSideBarList";

const drawerWidth = 240;

// const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
//   ({ theme, open }) => ({
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create("margin", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: `-${drawerWidth}px`,
//     ...(open && {
//       transition: theme.transitions.create("margin", {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginLeft: 0,
//     }),
//   })
// );

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.only("xs")]: {
      width: `calc(100% - ${0}px)`,
    },
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Sidebar() {
  const theme = useTheme();
  const { auth } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(window.innerWidth > 600);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [selectedItem, setSelectedItem] = useState(null);
 
 
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
      setOpen(window.innerWidth > 600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile, open]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleCloseMobileDrawer = () => {
    if (isMobile && open) {
      setOpen(false);
    }
  };

  const handleListItemClick = (index) => {
    setSelectedItem(index);
  };

  

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} elevation={0}>
        <Toolbar sx={{ backgroundColor: "#fff" }}>
          <IconButton
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Header />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          "@media(max-width:600px)": {
            width: 0,
          },
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxShadow: "0px 1px 14px 0px #00000017",
            border: "none",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
        onClick={handleCloseMobileDrawer}
      >
        <DrawerHeader sx={{ justifyContent: "center" }}>
          <IconButton>
            <Typography
              sx={{
                height: "50px",
                width: "50px",
              }}
              component={"img"}
              src={Logo}
            ></Typography>
          </IconButton>
          {isMobile && (
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ArrowBackIcon sx={{ color: "#fff" }} />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          )}
        </DrawerHeader>
        <List>
          {HrmsSideBarList?.filter(
            (item) => !(auth?.role === "agent" && item?.text === "Users")
          ).map((list, index) => (
            <Link
              key={index}
              to={
                list.customLink && auth?.role === "agent"
                  ? `${list.customLink}/${auth?._id}`
                  : list.link
              }
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <ListItem
                disablePadding
                sx={{
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  display: "block",
                  borderRadius: "10px",
                  
                  "&:hover":
                    selectedItem === index
                      ? "#eacf98"
                      : location.pathname === list.link
                      ? "#fff"
                      : "transparent",
                }}
                onClick={() => handleListItemClick(index, list)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: "center",
                    px: 2,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                    }}
                  >
                    <list.icons
                      sx={{
                        color:
                          selectedItem === index
                            ? "#FFC857"
                            : location.pathname === list.link
                            ? "#FFC857"
                            : "#463812",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={list.text}
                    sx={{
                      "& .MuiTypography-root": {
                        fontWeight: 600,
                        fontFamily: "",
                        fontSize: "15px",
                        color:
                          selectedItem === index
                            ? "#FFC857"
                            : location.pathname === list.link
                            ? "#FFC857"
                            : "#463812",
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
        <Box
          sx={{
            marginTop: "auto",
            textAlign: "center",
            marginBottom: "1.5rem",
            display: "flex",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Link
            to={"/"}
            style={{
              padding: "2px",
              color: "#FFC857",
              alignItems: "center",
              display: "flex",
              gap: 3,
            }}
          >
            <ArrowBackIcon sx={{ color: "#FFC857" }} />
            GO TO CRM
          </Link>
        </Box>
      </Drawer>
      {/* Backdrop to close the drawer when clicked outside */}
      {/* {open && (
        <Backdrop
          onClick={handleDrawerClose}
          open={open}
          sx={{ backgroundColor: open ? "#fff" : "rgba(0, 0, 0, 0.5)" }}
        />
      )} */}
    </Box>
  );
}
