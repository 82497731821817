import React from "react";
import TextField from "@mui/material/TextField";
import { Typography, InputAdornment } from "@mui/material";
import uploadImage from "../../assets/uploadImage.svg";

const CustomFileInput = ({
  label,
  style,
  borderRadius,
  labelStyle,
  placeholder,
  onChange,
  disable=false,
  accept,
  multiple,

  ...props
}) => {
  return (
    <>
      <Typography
        sx={{
          color: "#000000",
          fontWeight: "600",
          fontSize: "15px",
          marginBottom: "2px",
          "@media(max-width:600px)": {
            fontSize: "13px",
          },
          ...labelStyle,
        }}
      >
        {label}
      </Typography>
      {/* <input type="file" accept="image/*" multiple onChange={onChange} /> */}
      <TextField
        placeholder={placeholder}
        multiple
        size="small"
        type="file"
        inputProps={{multiple: true }} 
        disabled={disable}
        onChange={onChange}
        sx={{
           width: "100%",
           "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
              border: "0.94px solid rgba(220, 220, 220, 1)"
            },
            "&.Mui-focused fieldset": { 
              border: "0.94px solid rgba(220, 220, 220, 1)"
            },
          }
          }}
        InputProps={{
          style: {
            cursor: "pointer",
            borderRadius: "7.5px",
            fontSize: "12px",
            fontWeight: "400",
            "&::placeholder": {
              color: "#898989",
              
            },
            
          },
          endAdornment: (
            <InputAdornment position="end">
              <img src={uploadImage} alt="File Icon" width="15" height="15" />
            </InputAdornment>
          ),
         
        }}
        {...props}
        
      />
    </>
  );
};

export default CustomFileInput;
