import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";

export const meetingsFrBApi = createApi({
  reducerPath: "meetingFrbooking",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Meetings"],
  endpoints: (builder) => ({
    getMeetsData: builder.query({
      query: ({ page = 1, limit, search, pagination = true }) => ({
        url: Apis.getMeetingForBooking,
        params: {
          page,
          limit,
          search,
          pagination,
        },
      }),
      providesTags: ["Meetings"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetMeetsDataQuery } = meetingsFrBApi;
