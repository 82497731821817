import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";

const CustomRadio = ({
  label,
  items = [],
  value,
  handleChange,
  name,
  row = false,
  size = "small",
}) => {
  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name={name}        
        row={row}
        value={value}
        onChange={handleChange}
        si
      >
        {items?.map((item) => (
          <FormControlLabel
            value={item.value}
            control={<Radio size={size} />}
            label={item.label}
            key={item.value}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default CustomRadio;
