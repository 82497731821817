import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_AXIOS } from "../../http/interceptor";
import Apis from "../../utils/apis";
import { jsonSort } from "../../utils/commonMethods";

const initialState = {
  meetings: [],
  meetingsLoading: false,
};

export const getMeetings = createAsyncThunk("meetingList", async (thunkApi) => {
  try {
    const { data } = await API_AXIOS.get(Apis.meeting);

    return jsonSort(data, "updatedAt");
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const meetingSlice = createSlice({
  name: "meeting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMeetings.pending, (state) => {
      state.meetingsLoading = true;
    });
    builder.addCase(getMeetings.fulfilled, (state, { payload }) => {
      state.meetingsLoading = false;
      state.meetings = payload;
    });
    builder.addCase(getMeetings.rejected, (state) => {
      state.meetingsLoading = false;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = counterSlice.actions;

export default meetingSlice.reducer;
