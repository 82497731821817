import React from "react";
import { styled } from "@mui/material/styles";
import { FormControlLabel, Checkbox } from "@mui/material";

const BpIcon = styled("span", { shouldForwardProp: (prop) => prop !== "iconStyle" && prop !== "labelStyle" })(({ theme, iconStyle, labelStyle }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  border: "1px solid #DCDCDC",
  backgroundColor: theme.palette.mode === "dark" ? "" : "#fff",
  ...iconStyle,
  ...labelStyle
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#FFC857",
});

const CheckBox = (props) => {
  const { label, iconStyle, labelStyle, checked, onChange, disabled = false,  ...checkboxProps} = props;

  return (
    <FormControlLabel
      control={
        <Checkbox
          {...checkboxProps}
          disabled={disabled}
          disableRipple
          checked={checked}
          onChange={onChange}
          color="default"
          checkedIcon={<BpCheckedIcon iconStyle={iconStyle} />}
          icon={<BpIcon iconStyle={iconStyle} />}
          inputProps={{ "aria-label": "Checkbox demo" }}
        />
      }
      label={<span style={{ ...labelStyle, color: "rgba(137, 137, 137, 1)" }}>{label}</span>}
      
    />
  );
};

export default CheckBox;
