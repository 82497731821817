import React from "react";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";

const MultiSelect = ({ value, handleChange, title, name, items = [] }) => {
  return (
    <FormControl sx={{ width: "100%" }} size="small">
      {title && <InputLabel id="demo-multiple-chip-label">{title}</InputLabel>}
      <Select
        multiple
        value={value}
        onChange={(event) => {
          const {
            target: { value },
          } = event;
          handleChange(
            name,
            typeof value === "string" ? value.split(",") : value
          );
        }}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
            }}
          >
            {selected?.map((value) => (
              <Chip
                key={value}
                label={items?.filter((item) => item.value === value)[0]?.label}
              />
            ))}
          </Box>
        )}
      >
        {items?.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelect;
