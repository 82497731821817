import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, TextField, Badge, MenuItem } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { logout } from "../../store/reducers/auth";
import LogoutIcon from "@mui/icons-material/Logout";
import CustomMenu from "./CustomMenu";
import { HrmsNotification } from "./HrmsNotification";


export const Header = () => {
  const location = useLocation();
  const { id } = useParams();
  const { auth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let path = location.pathname.split("/");
  let header = path.at(-1);
  const [isScrolled, setIsScrolled] = useState(false);
  const CapitalHeader = header?.charAt(0).toUpperCase() + header.slice(1);
  //model
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorE2)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickProfile = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setAnchorE2(null);
  };

  const handleLogout = useCallback(() => {
    localStorage.clear();
    dispatch(logout());
    navigate("/");
  }, [dispatch, navigate]);

  
const handleProfile = ()=>{
  handleCloseProfile()
   navigate(`userDetails/${auth?._id}`)
}

  

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexGrow: 1,
        position: "sticky",
        top: 0,
        padding: 2,
        borderBottom: isScrolled ? " 1px solid #e4e2e0" : "none",
        "@media(max-width: 600px)":{
             justifyContent: "flex-end"
          }
      }}
    >
      {id ? (
        <Typography sx={{ color: "#fff" }}>.</Typography>
      ) : (
        <Typography
          sx={{
            color: "#222B45",
            fontSize: "20px",
            fontWeight: "800",
            "@media(max-width: 600px)": {
              display: "none",
            },
          }}
        >
          {CapitalHeader}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          gap: "2rem",
          alignItems: "center",
          justifyContent: "center",
          // "@media(max-width: 600px)":{
          //    justifyContent: "end"
          // }
        }}
      >
        {/* <Box>
          <TextField
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton size="small">
                    <SearchIcon sx={{ fontSize: "1.2rem" }} />
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                borderRadius: "0.5rem",
                backgroundColor: "#F9F9F9",
                borderBottom: "none !important",
              },
              inputProps: {
                style: {
                  height: "1.5rem",
                  padding: "4px",
                },
              },
            }}
            sx={{
              width: "100%",
              "@media(max-width: 600px)": {
                width: "100%",
              },
            }}
          />
        </Box> */}

        <CustomMenu
          open={open}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorEl={anchorEl}
          style={{ marginTop: "8px" }}
          buttonLabel={
            <Box sx={{ position: "relative", padding: "0px" }}>
              <NotificationsIcon sx={{ color: "#231F20" }} />
              <Badge
                color="error"
                variant="dot"
                sx={{
                  position: "absolute",
                  top: 4,
                  right: 6,
                  width: 8,
                  height: 8,
                }}
              />
            </Box>
          }
        >
          <HrmsNotification handleClose={handleClose} />
        </CustomMenu>
        <CustomMenu
          open={open2}
          handleClick={handleClickProfile}
          handleClose={handleCloseProfile}
          anchorEl={anchorE2}
          buttonLabel={
            <PersonIcon sx={{ color: "#231F20", marginTop: "5px" }} />
          }
        >
          <Box
            sx={{
              padding: "0.2rem 1rem",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <PersonIcon sx={{ color: "#8F9BB3" }} />
              <Typography
                sx={{ color: "#463812", fontSize: "14px", fontWeight: "400" }}
                onClick={handleProfile}
              >
                View Profile
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleLogout}
            >
              <LogoutIcon sx={{ color: "#8F9BB3" }} />
              <Typography
                sx={{ color: "#463812", fontSize: "14px", fontWeight: "400" }}
              >
                Logout
              </Typography>
            </Box>
          </Box>
        </CustomMenu>
      </Box>
    </Box>
  );
};
