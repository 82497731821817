import React from "react";
import { Box, Typography } from "@mui/material";

export const CommonHeader = ({ title, children, style }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        ...style,
      }}
    >
      <Typography
        sx={{
          color: "#222B45",
          fontSize: "1.5rem",
          fontWeight: "600",
          borderBottom: "1px solid #FFC857",
          width: "fit-content",
          "@media(max-width: 600px)": {
            fontSize: "1.5rem",
          },
          ...style,
        }}
      >
        {title}
      </Typography>
      {children && children}
    </Box>
  );
};
