import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";

export const leadDetailsApi = createApi({
  reducerPath: "leadDetails",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["LeadDetail"],
  endpoints: (builder) => ({
    getLeadDetailsById: builder.query({
      query: (id) => ({
        url: `${Apis.getLeadById}/${id}`,
      }),
      providesTags: ["LeadDetail"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetLeadDetailsByIdQuery } = leadDetailsApi;
