import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_AXIOS } from "../../http/interceptor";
import Apis from "../../utils/apis";
import { jsonSort } from "../../utils/commonMethods";

const initialState = {
  bookings: [],
  bookingsLoading: false,
};

export const getBookigs = createAsyncThunk("bookingList", async (thunkApi) => {
  try {
    const { data } = await API_AXIOS.get(Apis.booking);

    return jsonSort(data, "updatedAt");
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBookigs.pending, (state) => {
      state.bookingsLoading = true;
    });
    builder.addCase(getBookigs.fulfilled, (state, { payload }) => {
      state.bookingsLoading = false;
      state.bookings = payload;
    });
    builder.addCase(getBookigs.rejected, (state) => {
      state.bookingsLoading = false;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = counterSlice.actions;

export default bookingSlice.reducer;
