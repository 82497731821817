import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";
import { API_AXIOS } from "../../http/interceptor";

const getDashboardBooking = async () => {
  try {
    const { data } = await API_AXIOS.get(Apis.bookingDashboard);
    return { data };
  } catch (error) {
    return {
      error: error.response ? error.response.data : error.message,
    };
  }
};

export const dashboardBookingApi = createApi({
  reducerPath: "dashboardBooking",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Dashboard"],
  endpoints: (builder) => ({
    getDashboardBooking: builder.query({
      queryFn: getDashboardBooking,
      providesTags: ["Dashboard"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetDashboardBookingQuery } = dashboardBookingApi;
