import { Dashboard } from "@mui/icons-material";
import { Box, MenuItem } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useGetLatestAuthQuery } from "../../store/services/authService";
import { isAdminSrmanager } from "../../utils/menuItems";

const HrmsButton = () => {
  const { auth } = useSelector((state) => state.auth);
  const { data: user } = useGetLatestAuthQuery(auth?._id);

  if (!isAdminSrmanager.includes(auth?.role) && user?.status !== "approved")
    return null;

  return (
    <Link to={"HrManagement/dashboard"}>
      <Box sx={{ display: "flex" }}>
        <MenuItem>
          <Dashboard sx={{ color: "#FFC857" }} />
        </MenuItem>
        <MenuItem>HRMS</MenuItem>
      </Box>
    </Link>
  );
};

export default HrmsButton;
