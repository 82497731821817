import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  query: null,
  exist: false,
  type: "",
};

const querySlice = createSlice({
  name: "query",
  initialState,
  reducers: {
    setQuery: (state, { payload }) => {
      state.query = payload.values;
      state.exist = true;
      state.type = payload.type;
    },
    clearQuery: (state) => {
      state.query = null;
      state.exist = false;
      state.type = "";
    },
  },
});

export const { setQuery, clearQuery } = querySlice.actions;
export default querySlice.reducer;
