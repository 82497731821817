import React from "react";
import TextField from "@mui/material/TextField";
import { Typography, Box } from "@mui/material";


const CustomTextInput = ({
  label,
  style,
  borderRadius,
  labelStyle,
  placeholder,
  onClick,
  onChange,
  disabled = false,
  name,
  error,
  value,
  multiline,
  rows,
  ...props
}) => {
  const isError = !!error;
  
  
  return (
    <Box>
      <Typography
        sx={{
          color: "#000000",
          fontWeight: "600",
          fontSize: "15px",
          marginBottom: "2px",
          "@media(max-width:600px)": {
            fontSize: "13px",
          },
          ...labelStyle,
        }}
      >
        {label}
      </Typography>
      <TextField
        disabled={disabled}
        placeholder={placeholder}
        name={name}
        size="small"
        onChange={onChange}
        multiline={!!multiline}
        rows={rows}
        value={value}
        sx={{
          width: "100%",
          borderColor: "1px solid #463812",
          backgroundColor: "#FDFDF9",
          "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
              border: "1px solid #DCDCDC",
            },
            "&.Mui-focused fieldset": {
              border: isError ? "1px solid red" : "1px solid #DCDCDC",
            },
          },
        }}
        InputProps={{
          style: {
            borderRadius: "7.5px",
            fontSize: "14px",
            fontWeight: "400",
            "&::placeholder": {
              color: "#898989",
            },
          },
        }}
        {...props}
      />
      {error && (
        <Typography
          sx={{
            color: "red",
            marginTop: "0px",
            fontSize: "14px",
          }}
        >
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default CustomTextInput;
