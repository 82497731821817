import { Button, CircularProgress } from "@mui/material";
import React from "react";

const LoadButton = ({ children, loading, ...props }) => {
  return (
    <Button {...props} disabled={loading}>
      {loading ? <CircularProgress sx={{ padding: "8px" }} /> : children}
    </Button>
  );
};

export default LoadButton;
