import React, { useCallback,  useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { API_AXIOS } from "../http/interceptor";
import Apis from "../utils/apis";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { formatTime, formatDate } from "../utils/commonMethods";
import { FiberManualRecord } from "@mui/icons-material";
// import { setNotifications } from "../store/reducers/auth";
import { useNavigate } from "react-router-dom";
import { useGetNotificationsQuery } from "../store/services/notification";
import LoadButton from "./LoadButton";

// import { io } from "socket.io-client";

// const socket = io("http://localhost:5000");

const Notification = ({ onClose }) => {
  const [page, setPage] = useState(1);
  const { auth } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  let lastDisplayedDate = null;

  let url = `user/getNotificationsById/${auth?._id}?page=${page}`;

  const { data, isFetching, refetch } = useGetNotificationsQuery(url);

  const notificationSeen = async (notifyId, type) => {
    try {
      const { data } = await API_AXIOS.post(
        `${Apis.notificationSeenById}/${auth?._id}`,
        { notifyId }
      );
      // toast.success(data);
      /******check notification type **********/
      if (type === "Lead") {
        navigate("/leads");
      } else if (type === "Meeting") {
        navigate("/meetings");
      } else if (type === "Booking") {
        navigate("/bookings");
      }

      refetch();
      // getNotifications();
      onClose();
    } catch (err) {
      toast.error(err?.response?.data);
    }
  };

  // const getNotifications = useCallback(async () => {
  //   try {
  //     const { data } = await API_AXIOS.get(
  //       `${Apis.getNotificationById}/${auth?._id}`
  //     );
  //     dispatch(setNotifications(data));
  //   } catch (err) {
  //     console.log(err.response.data);
  //   }
  // }, [auth, dispatch]);

  const formatNotificationDate = (notificationDateStr) => {
    const notificationDateTime = new Date(
      notificationDateStr.split("/").reverse().join("-") + "T00:00:00"
    );
    const currentDate = new Date();
    const timeDifference = currentDate - notificationDateTime;
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

    if (timeDifference < oneDayInMilliseconds) {
      return "Today";
    } else if (timeDifference < 2 * oneDayInMilliseconds) {
      return "Yesterday";
    } else {
      return notificationDateTime.toLocaleDateString();
    }
  };

  const isNext = useCallback(() => {
    setPage((page) => page + 1);
  }, []);

  const isPrev = useCallback(() => {
    if (page > 1) {
      setPage((page) => page - 1);
    }
  }, [page]);

  return (
    <Box sx={{ p: { xs: "1rem 1rem", sm: "2rem 2rem" } }}>
      {data?.length > 0 ? (
        <>
          {data?.map((notification, index) => {
            const currentDate = formatDate(notification?.time);
            const shouldDisplayDate = currentDate !== lastDisplayedDate;
            lastDisplayedDate = currentDate;
            return (
              <Box
                key={notification?._id}
                sx={{
                  borderRadius: "5px #FFC857",
                  p: { xs: "0rem 1rem ", sm: "0rem 1rem" },
                }}
              >
                {shouldDisplayDate && (
                  <>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: "1rem",
                          fontWeight: "700",
                        }}
                      >
                        {formatNotificationDate(currentDate)}
                      </Typography>
                      <Divider
                        sx={{
                          backgroundColor: "#FFC857",
                          width: "70%",
                          margin: "0 auto",
                        }}
                      />
                    </Box>
                  </>
                )}
                <React.Fragment key={notification?._id}>
                  <Box
                    onClick={() =>
                      notificationSeen(notification?._id, notification?.type)
                    }
                    key={index}
                    sx={{
                      display: "flex",
                      gap: 2,
                      cursor: "pointer",
                      marginTop: "1rem",
                      p: { xs: "2px", sm: "1px" },
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        borderRadius: "50%",
                        width: "46px",
                        height: "46px",
                        backgroundColor: "#BFBFBF",
                        justifyContent: "center",
                        "@media(max-width: 600px)": {
                          width: "25px",
                          height: "25px",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: { xs: "15px", sm: "2rem", md: "1.8rem" },
                          textAlign: "center",
                        }}
                      >
                        {notification?.type?.charAt(0)?.toUpperCase()}
                      </Typography>
                      {!notification?.seen && (
                        <FiberManualRecord
                          sx={{
                            color: "green",
                            fontSize: "0.8rem",
                            position: "absolute",
                            top: 0,
                            right: 0,
                          }}
                        />
                      )}
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        sx={{
                          fontSize: { xs: "14px", sm: "18px", md: "1rem" },
                          fontWeight: "700",
                          lineHeight: "25.78px",
                          color: "#000000",
                        }}
                      >
                        {notification?.type}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 4,
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "12px", sm: "18px", md: "14px" },
                          }}
                        >
                          {notification?.message}
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: "16.41px",
                            fontSize: "11px",
                            fontWeight: "700",
                            color: "#000000",
                          }}
                        >
                          {formatTime(notification?.time)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider
                    sx={{ backgroundColor: "#131313", marginTop: "0.6rem" }}
                  />
                </React.Fragment>
              </Box>
            );
          })}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <LoadButton
              size="small"
              variant="contained"
              color="custom"
              onClick={isPrev}
            >
              Prev
            </LoadButton>
            <LoadButton
              size="small"
              variant="contained"
              color="custom"
              onClick={isNext}
              loading={isFetching}
            >
              Next
            </LoadButton>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Typography
            sx={{ fontSize: "1.1rem", fontWeight: 550, textAlign: "center" }}
          >
            Empty
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Notification;
