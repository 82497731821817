import React, { useCallback, useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { useGetHrmsNotificationsQuery } from "../../store/services/hrmsNotifications";
import { formatTime } from "../../utils/commonMethods";
import LoadButton from "../LoadButton";
import { useNavigate } from "react-router-dom";

let links = {
  leave: "/HrManagement/leaveDetails/",
  attendance: "/HrManagement/attendenceDetails/",
  user: "/HrManagement/userDetails/",
};

export const HrmsNotification = ({ handleClose }) => {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  let url = `hrms/user/getNotifications?page=${page}`;

  const { data, isFetching } = useGetHrmsNotificationsQuery(url);

  const isNext = useCallback(() => {
    setPage((page) => page + 1);
  }, []);

  const isPrev = useCallback(() => {
    if (page > 1) {
      setPage((page) => page - 1);
    }
  }, [page]);

  const handleNavigation = (url) => {
    navigate(url);
    handleClose();
  };

  return (
    <Box
      sx={{
        padding: "0rem 1.5rem",
        width: "600px",
        textAlign: "center",
        justifyContent: "right",
        "@media(max-width: 600px)": {
          width: "fit-content",
          padding: "0rem 0.5rem",
        },
      }}
    >
      <Typography
        sx={{ color: "#463812", fontSize: "25px", fontWeight: "700" }}
      >
        Notification
      </Typography>
      {/* <Divider sx={{ marginTop: "1rem" }}>Today</Divider> */}
      {data?.map((data, index) => {
        return (
          <Box
            key={data._id}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              border: "1px solid #A0A0A0",
              borderRadius: "10px",
              padding: 1,
              marginTop: "1rem",
              marginBottom: "1.5rem",
            }}
          >
            {/* <Link to={`${links[data.type]}${data.dataId}`}> */}
            <Box
              onClick={() =>
                handleNavigation(`${links[data.type]}${data.dataId}`)
              }
              sx={{ display: "flex", gap: 2, cursor: "pointer" }}
            >
              <Box
                sx={{
                  backgroundColor: "#F9837C",
                  padding: "20px 20px",
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ color: "#fff", textAlign: "center" }}>
                  {data?.type?.charAt(0)?.toUpperCase()}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                }}
              >
                <Typography
                  sx={{
                    color: "#463812",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                >
                  {data?.title}
                </Typography>
                <Typography
                  sx={{
                    color: "#898989",
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                >
                  {data?.message}
                </Typography>
              </Box>
            </Box>
            {/* </Link> */}

            <Box
              sx={{
                color: "#898989",
                fontSize: "10px",
                fontWeight: "400",
                width: "fit-content",
              }}
            >
              {formatTime(data?.time)}
            </Box>
          </Box>
        );
      })}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <LoadButton
          onClick={isPrev}
          variant="contained"
          size="small"
          color="custom"
          disabled={page === 1 ? true : false}
        >
          Prev
        </LoadButton>
        <LoadButton
          onClick={isNext}
          variant="contained"
          size="small"
          color="custom"
          loading={isFetching}
        >
          Next
        </LoadButton>
      </Box>
    </Box>
  );
};
