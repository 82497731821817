import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { Box, Typography } from "@mui/material";

export default function CustomMenu({
  buttonLabel,
  children,
  style,
  open,
  anchorEl,
  handleClick,
  handleClose,
}) {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  return (
    <Box sx={{ ...style }}>
      <Typography
        sx={{ cursor: "pointer" }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {buttonLabel}
      </Typography>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: {
            padding: "0px",
          },
        }}
      >
        {children}
      </Menu>
    </Box>
  );
}
